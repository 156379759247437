<template lang="pug">
  div
    div.tunes
      div.enforce-max-width
        p.center-loader(v-if="isBusy")
          b-spinner.align-middle.mx-2
          strong Loading...
        div.content-to-fade(v-bind:class="ready")
          div
            p 100 tunes, 100 days
            .mb-0.mt-2#tune-search
              b-input-group(size="sm")
                b-form-input#filterInput(v-model="filter", type="search", placeholder="Type to Search")
                b-button.d-inline-block(:disabled="!filter", @click="filter = ''") Clear
          b-table.mt-3#tunechallenge-table(:items="tuneList", :fields="fields", :busy="isBusy", caption-top, primary-key="name", :filter="filter", :filter-included-fields="filterOn")
            template(#cell(index)="data")
              span.tune-index {{ data.item.index }}
            template(#cell(date)="data")
              span.tune-date {{ data.item.date }}
            template(#cell(name)="data")
              span.tune-name {{ data.item.name }}
            template(#cell(artist)="data")
              span.tune-artist {{ data.item.artist }}
            template(#cell(url1)="data")
              span.tune-url
                b-link(:href="data.item.url1", target="_blank") {{ data.item.url1 }}
            template(#cell(url2)="data")
              span.tune-url
                b-link(:href="data.item.url2", target="_blank") link
</template>

<script>
export default {
  name: "TuneChallenge",
  data() {
    return {
      isBusy: true,
      ready: "notready",
      tuneList: [],
      fields: [
        { key: "index", label: "#", sortable: true },
        { key: "date", label: "Date", sortable: true },
        { key: "name", label: "Title", sortable: true },
        { key: "artist", label: "Artist", sortable: true },
        { key: "url1", label: "URL" },
        { key: "url2", label: "URL" }
      ],
      filter: null,
      filterOn: ["index", "date", "name", "artist"]
    };
  },
  created() {
    fetch(process.env.VUE_APP_100TUNESSHEET, {
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
        }
      })
      .then(response => response.json())
      .then(data => {
        var tuneData = [];

        data.values.forEach(function(value) {
          
          if (value[0] !== "#") {
            var entry = {
              index: value[0],
              date: value[1],
              name: value[2],
              artist: value[3],
              url1: value[4],
              url2: value[4]
            };
            
            tuneData.push(entry);
          }
          
        });
        this.tuneList = tuneData;
        this.isBusy = false;
        this.ready = "ready";
      });
  }
};
</script>

<style scoped lang="less">
.content-to-fade {
  opacity: 0;

  -webkit-transition: opacity 2.8s;
  -moz-transition: opacity 2.8s;
  transition: opacity 2.8s;

  &.ready {
    opacity: 1;
  }
}
</style>
